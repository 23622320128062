@keyframes pulsate {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1); /* Scale up to 110% */
    }
    100% {
        transform: scale(1); /* Scale back to original size */
    }
}


.creature-background {
    height: 100vh; /* Full view height */
    width: 100vw; /* Full view width */
    background: url('../images/bg.png') no-repeat center center fixed;
    background-size: cover; /* Cover ensures aspect ratio is maintained */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/* Container styling */
.creature-container {
    background-color: white; /* Or any color you prefer */
    padding: 15px 25px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
    text-align: center;
    width: 300px; /* Or any specific width */
}

/* Input styling */
.creature-container input[type="text"] {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 4px; /* Rounded corners for input */
}

.creature-page-img {
    border-radius: 50%;
}

.creature-page-logo {
    max-width: 250px;
    width: 100%;
    margin-bottom: 30px;
}

.free-mint-btn {
    width: 100%;
    max-width: 150px;
    margin-top: 20px;
    cursor: pointer;
    animation: pulsate 1s ease-in-out infinite;
}

.creature-actions {
    margin-top: 20px;
    font-weight: bold;
    color: #333333;
    font-size: 15px;
}