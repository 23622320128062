@keyframes pulse {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
}

.image-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 0 40px 40px 40px;
}

.grid-image {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    padding: 20px;
    object-fit: cover;
    opacity: 0;
    transform: translateY(20px) scale(0.75);
    transition: opacity 0.4s ease-out, transform 0.4s ease-out;
}

/* The .fade-in class will be added when the image is in the viewport */
.fade-in {
    opacity: 1;
    transform: translateY(0) scale(1);
}

/* Responsive styles */
@media (max-width: 800px) {
    .grid-image {
        width: 160px;
        height: 160px;
        padding: 5px;
    }

    .image-grid {
        padding: 0 20px 20px 20px;
        /*gap: 5px;*/
    }
}


@media (max-width: 400px) {
    .grid-image {
        width: 140px;
        height: 140px;
        padding: 5px;
    }

    .image-grid {
        padding: 0 20px 20px 20px;
        /*gap: 5px;*/
    }
}