@keyframes pulsate {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1); /* Scale up to 110% */
    }
    100% {
        transform: scale(1); /* Scale back to original size */
    }
}

.pixi-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
}

.below-game {
    position: relative;
    width: 100%;
    max-width: 640px;
}

.coin-row {
    color: white;
    display: flex;
    position: absolute;
    right: 0;
    gap: 5px;
}

.below-game-columns {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    margin-top: 10px;
}

.below-game-col {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.GamePage-Container {
    height: 100%;
    background: black;
}

.chat-input {
    position: relative;
    top: -46px;
    left: -25px;
    height: 32px;
}

.input-text {
    font-size: 25px;
    background: transparent;
    border: none;
    width: 570px;
    outline: none;
}

#chat-input {
    display: none;
}

.airdrop-txt {
    font-size: 12px;
    text-align: center;
    font-weight: bold;
}

.airdrop-btn {
    cursor: pointer;
    animation: pulsate 1.5s ease-in-out infinite;
    position: relative;
}

.num-coins {
    font-weight: bold;
}