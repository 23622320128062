.airdrop-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
}

.airdrop-dialog {
    background: white;
    padding: 20px 30px;
    border-radius: 5px;
    text-align: center;
    position: relative; /* For absolute positioning of the close button */
}

.airdrop-dialog-close {
    position: absolute;
    top: 10px;
    left: 15px;
    font-size: 25px;
    cursor: pointer;
    /* Style to ensure the 'X' appears as a button or link */
}

.airdrop-dialog-body-cols {
    margin-bottom: 30px;
    display: flex;
    gap: 20px;
}

.airdrop-dialog-instructions {
    max-width: 300px;
    text-align: left;
    font-size: 18px;
}

.airdrop-dialog-tweet-btn {
    padding: 20px 50px;
    font-size: 30px;
    font-weight: bold;
    background-color: #007bff; /* Example blue color, adjust as needed */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}

.airdrop-dialog-tweet-btn:hover {
    background-color: #0056b3; /* Darker shade for hover effect */
}

.referrals-holder {
    margin-top: 30px;
}

.rewards-tier {
    font-family: Arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.rewards-tier td, .rewards-tier th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.rewards-tier th {
    background-color: #f2f2f2;
}