/* Background styling */
.login-background {
    height: 100vh; /* Full view height */
    width: 100vw; /* Full view width */
    background: url('../images/bg.png') no-repeat center center fixed;
    background-size: cover; /* Cover ensures aspect ratio is maintained */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Container styling */
.login-container {
    background-color: white; /* Or any color you prefer */
    padding: 20px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
    text-align: center;
    width: 300px; /* Or any specific width */
}

/* Input styling */
.login-container input[type="text"] {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 4px; /* Rounded corners for input */
}

/* Additional information text styling */
.login-info {
    color: #666; /* Choose a color that fits your design */
    font-size: 0.9rem; /* Adjust size as needed */
    margin-bottom: 20px;
}

/* Continue button styling */
.continue-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff; /* Example blue color, adjust as needed */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.continue-button:hover {
    background-color: #0056b3; /* Darker shade for hover effect */
}

/* Resend code button styling */
.resend-code-button {
    width: 100%;
    padding: 10px;
    background-color: #165d0b; /* Example gray color, adjust as needed */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.resend-code-button:hover {
    background-color: #3db423; /* Darker shade for hover effect */
}

.continue-button:disabled, .resend-code-button:disabled {
    background-color: gray; /* Gray color when the button is disabled */
    cursor: not-allowed; /* Cursor indicates the button is not clickable */
}

.error-message {
    font-weight: bold;
}