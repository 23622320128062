.logo {
    max-width: 450px;
    width: 100%;
}

body {
    background: #f5f5f5;
}

.title {
    font-size: 50px;
    color: white;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
}

.subtitle {
    font-size: 30px;
    font-weight: bold;
    color: white;
    text-align: center;
    margin: 20px 20px 0 20px;
    font-family: 'Press Start 2P', sans-serif;
}

.above-the-fold-container {
    background: #0b3b2c;
    background-image: url('../images/bg.png');
    background-size: cover;
    background-position: center;
}

.cta-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 280px;
}

.cta-btn {
    margin-top: 30px;
    padding: 15px 45px;
    cursor: pointer;
}

.explanation-container {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap onto multiple lines */
}

.explanation-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 10px 20px;
}

.below-the-fold-container {
    max-width: 850px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 3px 8px -2px rgba(0,0,0,0.65);
    margin: auto;
    position: relative;
    top: -180px;
    padding: 30px 20px 80px 20px;
}

.how-it-works-text {
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 60px;
}

.explanation-text {
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
    line-height: 25px;
    font-weight: bold;
}

.explanation-img {
    width: 200px;
}

.powered-by-container {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -180px;
}

.trailer-container {
    display: flex;
    flex-direction: column;
    position: relative;
    background: rgba(20, 20, 20, 1);
    padding: 100px;
    overflow: hidden; /* This will contain the pseudo-elements within the bounds of the container */
}

.section-title {
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    margin-top: 50px;
}

.grid-title {
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.offset-container {
    position: relative;
    top: -180px;
}

.powered-by {
    font-weight: bold;
    font-size: 19px;
    color: black;
    margin: 0 auto 100px auto;
}

.beam-svg {
    position: relative;
    top: 7px;
}

.mobile-error {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 40px;
    text-align: center;
    font-weight: bold;
    height: 100%;
    margin: 0 20px;
}

.socials-container {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    margin-right: 10px;
}

.pointer {
    cursor: pointer;
    margin-right: 10px;
}

.video-container {
    display: flex;
    justify-content: center;
}

.investors-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.investor-icon {
    width: 500px;
}

.merit-desc {
    font-size: 15px;
    font-weight: bold;
    position: relative;
    top: -15px;
    margin-bottom: 20px;
}

.merit-desc > span {
    font-size: 20px;
}

@media (max-width: 1200px) {
    .trailer-container {
        padding: 40px;
    }
}

@media (max-width: 800px) {
    .trailer-container {
        padding: 0;
    }

    .investor-icon {
        width: 300px;
    }

    .section-title, .grid-title {
        font-size: 30px;
        margin-top: 45px;
    }

    .how-it-works-text {
        margin-bottom: 20px;
    }

    .explanation-text {
        margin-top: 5px;
        margin-bottom: 20px;
    }
}

/* Responsive styles */
@media (max-width: 600px) {
    .video-inner-container {
        max-width: 600px;
    }

    .video {
        max-width: 440px;
    }
}

@media (max-width: 440px) {
    .video-inner-container {
        max-width: 440px;
    }

    .video {
        max-width: 285px;
    }
}
